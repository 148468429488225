<template>
  <v-container
    id="extended-tables"
    fluid
    tag="section"
  >
    <div class="py-3" />
    <base-material-card
      color="success"
      icon="mdi-cart-outline"
      inline
      :title="$t('order.My_orders')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="client.Commandes"
      >
        <!-- Mode de paiement-->
        <template
          slot="item.mode_paiement"
          slot-scope="props"
        >
          <!-- Payable par code de facturation( UQAM UBR)-->
          <div v-if="props.item.mode_paiement === 1">
            <v-icon
              color="green"
              large
            >
              mdi-book-open-variant
            </v-icon>
            {{ getOrderCodeName }}
          </div>

          <!-- Carte de crédit-->
          <div v-if="props.item.mode_paiement === 2">
            <v-icon
              color="primary"
              large
            >
              mdi-credit-card
            </v-icon>
            {{ $t('order.Credit_card') }}
          </div>
        </template>

        <!-- Type-->
        <!-- Nouvelle ligne-->
        <template
          slot="item.type"
          slot-scope="props"
        >
          <div v-if="props.item.type === 1">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim-outline
            </v-icon>
            {{ $t('order.type.1') }}
          </div>
          <!-- Migration-->
          <div v-if="props.item.type === 2">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim
            </v-icon>
            {{ $t('order.type.2') }}
          </div>
          <!-- Achat d'équipement-->
          <div v-if="props.item.type === 3">
            <v-icon
              color="grey lighten-1"
              large
            >
              {{ (props.item.type_equipement === 1) ? 'mdi-cellphone-nfc': 'mdi-mini-sd' }}
            </v-icon>
            {{ $t('order.type.3') }}
          </div>
        </template>

        <!-- Statut-->
        <template
          slot="item.statut"
          slot-scope="props"
        >
          <span>{{ getStatusName(props.item.statut) }}</span>
          <!-- {{ orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].action }} -->
          <!-- Si il y a un rendez-vous, on affiche la date-->
          <div>
            <template v-if="props.item.rdv_date && props.item.statut !== 8">
              <span>{{ $t('Appointment') }}: </span>
              <span class="red--text font-weight-bold">{{ getPickupDate(props.item.rdv_date) }}</span>
              <v-btn
                :disabled="!props.item.rdv_confirme"
                icon
                @click="makeAppointment(props.item)"
              >
                <v-icon
                  class="mr-2"
                  size="x-large"
                  color="grey"
                >
                  mdi-calendar-edit-outline
                </v-icon>
              </v-btn>
            </template>
          </div>
        </template>

        <!-- Intervention (Bouton spécial)-->
        <template
          slot="item.intervention"
          slot-scope="props"
        >
          <template v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action">
            <!-- Bouton-->
            <v-btn
              v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.bouton"
              class="mb-2"
              elevation="2"
              small
              :color="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.couleur"
              @click="handleFunctionCall(orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.fonction.nom, props.item)"
            >
              <v-icon
                v-if="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.icon"
                left
                dark
                :color="orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.icon.couleur"
              >
                {{ orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.icon.nom }}
              </v-icon>
              {{ $t(orderStep.type[props.item.type].statut[props.item.statut].etape[props.item.etape].mesCommandes.action.nom) }}
            </v-btn>
          </template>

          <!-- Bouton d'autorisation de transfert de ligne vers Telus-->
          <!-- <v-btn
            v-if="props.item.migration_type === 1 && props.item.statut === 2 && props.item.etape === 2"
            class="ml-2"
            elevation="2"
            small
            color="blue"
            @click="orderComfirmAutorisation(props.item)"
          >
            {{ $t('order.operation.action.Confirm_autorisation') }}
          </v-btn> -->
        </template>

        <!-- Action-->
        <template
          slot="item.action"
          slot-scope="props"
        >
          <!--  Edit ou voir-->
          <v-btn
            fab
            light
            x-small
            :color="getEditButton(props.item.statut, props.item.etape) ? 'green' : 'white'"
            :title="getEditButton(props.item.statut, props.item.etape) ? $t('order.Edit_order') : $t('order.View_order')"
            @click="editView(props.item)"
          >
            <!-- Si le statut est plus grand que deux, le client peut éditer sa commande. On affiche l'icône du crayon si on affiche l'icône de l'oeil-->
            <v-icon>{{ getEditButton(props.item.statut, props.item.etape) ? 'mdi-pencil' : 'mdi-eye-outline' }}</v-icon>
          </v-btn>

          <!-- Supprimer-->
          <v-btn
            :disabled="props.item.statut !== 1"
            class="ml-2"
            fab
            light
            x-small
            color="red"
            :title="$t('Delete')"
            @click="remove(props.item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>

    <!-- DIALOG-->
    <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
    <v-dialog
      v-model="dialog3"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog3 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-credit-card
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_credit_card') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)-->
        <order-new-line-credit
          ref="orderNewLineCredit"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-mode-paiement="parameterModePaiement"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog1"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog1 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-book-open-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_line') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- Composant pour COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)-->
        <order-new-line-ubr
          ref="orderNewLineUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type1="parameterPickupDeliveryType1"
          :parameter-pickup-delivery-type2="parameterPickupDeliveryType2"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
    <v-dialog
      v-model="dialog2"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialog2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-phone
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('order.Order_cell_phone') }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('order.Payable_by_account') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)-->
        <order-new-phone-ubr
          ref="orderNewPhoneUbr"
          :client="client"
          :state="state"
          :parameter-pickup-delivery-type3="parameterPickupDeliveryType3"
          :parameter-longueur-code-facturation="parameterLongueurCodeFacturation"
          @submit="submit"
        />
      </v-card>
    </v-dialog>

    <!-- Afficher le contrat-->
    <v-dialog
      v-model="dialogContrat"
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('order.Confirm_order') }}</span>
          <v-icon
            large
            aria-label="Close"
            @click="dialogContrat = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h4>{{ $t('order.Confirming_order_contract') }}:</h4>
            <br>
            <hr>
            <br>
            <br>
            <div v-html="contratConfirmation" />
            <validation-observer
              ref="obs"
              v-slot="{ invalid, handleSubmit }"
            >
              <form>
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('order.validation.Billing_confirmation')"
                  rules="required"
                >
                  <v-checkbox
                    v-model="contratCheckBoxConfirmation"
                    class="mt-0"
                    :value="true"
                    :error-messages="errors"
                    :success="valid"
                    required
                  >
                    <span
                      slot="label"
                      class="red--text"
                    >{{ $t('order.validation.condition_accepted') }}</span>
                  </v-checkbox>

                  <v-btn
                    :disabled="invalid"
                    class="mt-3"
                    color="success"
                    @click="handleSubmit(orderComfirmation)"
                  >
                    {{ $t('order.Confirm_order') }}
                  </v-btn>
                </validation-provider>
              </form>
            </validation-observer>
          </v-container>
        </v-card-text>
        <p class="font-weight-thin mr-1 text-right caption">
          ref:8
        </p>
      </v-card>
    </v-dialog>

    <!--  Dialog calendrier-->
    <v-dialog
      v-model="dialog4"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('order.Pickup') }}</span>
          <v-icon
            large
            aria-label="Close"
            @click="dialog4 = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              justify="center"
              align="center"
            >
              <client-commande-demo-uqam-dialog-make-appointment
                ref="ClientCommandeDemoUqamDialogMakeAppointment"
                @submit="submit"
                @submitAddToCalendar="submitAddToCalendar"
              />
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Ajouter au calendrier de l'utilisateur-->
    <v-dialog
      v-model="dialog5"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('order.Add_to_calender') }}</span>
          <v-icon
            large
            aria-label="Close"
            @click="dialog5 = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                :md="disabledIOSCalander ? 1 : 2"
              />

              <v-col
                cols="12"
                md="2"
              >
                <div>
                  <div class="text-center">
                    <v-btn
                      class="mb-2"
                      icon
                      @click="redirect('outlook')"
                    >
                      <v-img
                        src="img/outlook.png"
                        width="70"
                      />
                    </v-btn>
                  </div>
                  <div class="text-center">
                    <span>Outlook</span>
                  </div>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <v-btn
                    class="mb-2"
                    icon
                    @click="redirect('office365')"
                  >
                    <v-img
                      src="img/outlook-office-365.png"
                      width="80"
                    />
                  </v-btn>
                </div>
                <div class="text-center">
                  <span>Outlook Office 365</span>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <v-btn
                    class="mb-2"
                    icon
                    @click="redirect('google')"
                  >
                    <v-img
                      src="img/google-calendar.png"
                      width="80"
                    />
                  </v-btn>
                </div>
                <div class="text-center">
                  <span>Google calendar</span>
                </div>
              </v-col>

              <v-col
                v-if="disabledIOSCalander"
                cols="12"
                md="2"
              >
                <div class="text-center">
                  <v-btn
                    class="mb-2"
                    icon
                    @click="redirect('ics')"
                  >
                    <v-img
                      src="img/apple-calendar.png"
                      width="70"
                    />
                  </v-btn>
                </div>
                <div class="text-center">
                  <span>Apple</span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  // Components
  import OrderNewLineUbr from '@/components/Client/Order/components/OrderNewLineUbr'
  import OrderNewPhoneUbr from '@/components/Client/Order/components/OrderNewPhoneUbr'
  import OrderNewLineCredit from '@/components/Client/Order/components/OrderNewLineCredit'
  import ClientCommandeDemoUqamDialogMakeAppointment from '@/components/Client/Commande/dialog/MakeAppointment'
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Mixins
  import { MyOrders } from '@/mixins/Client/Order/MyOrders'
  // Other
  import { mapState, mapGetters } from 'vuex'
  import Swal from 'sweetalert2'
  import { EventBus } from '@/utils/event-bus'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { ics, outlook, office365, google } from 'calendar-link'
  import { format, parseISO } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }

  export default {
    name: 'MyOrders',
    components: {
      OrderNewLineUbr,
      OrderNewPhoneUbr,
      OrderNewLineCredit,
      ClientCommandeDemoUqamDialogMakeAppointment,
      ValidationObserver,
      ValidationProvider,
    },
    mixins: [MyOrders],
    props: {
      client: Object,
      orderStep: Object,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        orderItem: null,
        state: null,
        dialog1: false,
        dialog2: false,
        dialog3: false,
        dialog4: false,
        dialog5: false,
        dialogContrat: false,
        contratConfirmation: null,
        contratCheckBoxConfirmation: null,
        message: null,
        messageConfirmation: null,
        messageRDV: null,
        tableHeaders: [
          { text: 'No', value: 'id', sortable: true, class: 'text-h4 primary--text', width: 100 },
          { text: this.$i18n.t('order.Payment_mode'), value: 'mode_paiement', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Description', value: 'type', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Client', value: 'Client.username', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Status'), value: 'statut', sortable: true, class: 'text-h4 primary--text' },
          { text: '', value: 'intervention', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'end', class: 'text-h4 primary--text', width: 150 },
        ],
        rdvDate: {},
      }
    },

    computed: {
      ...mapState(['userInfo']),
      ...mapGetters('parameters', {
        parameterPickupDeliveryType1: 'getPickupDeliveryType1',
        parameterPickupDeliveryType2: 'getPickupDeliveryType2',
        parameterPickupDeliveryType3: 'getPickupDeliveryType3',
        parameterModePaiement: 'getModePaiement',
        parameterLongueurCodeFacturation: 'getLongueurCodeFacturation',
        parameterInstitutionName: 'getInstitutionName',
      }),
      disabledIOSCalander () {
        const deviceDetect = navigator.platform

        const appleDevicesArr = ['MacIntel', 'MacPPC', 'Mac68K', 'Macintosh', 'iPhone',
                                 'iPod', 'iPad', 'iPhone Simulator', 'iPod Simulator', 'iPad Simulator']

        return appleDevicesArr.includes(deviceDetect)
      },
    },

    async mounted () {
      try {
        MessagesService.multiple({ messages: [14, 15, 28, 29] }).then(response => {
          // On récupère le message lors que le client fini d'éditer la commande
          this.message = response.find(item => item.no === 14)
          // Message quand le client appuie sur le bouton pour confirmer sa commande
          this.messageConfirmation = response.find(item => item.no === 15)
          // Message de confirmation de rendez-vous
          this.messageRDV = response.find(item => item.no === 28)
        })
      } catch (error) {
        console.log(error)
      }
    },

    methods: {
      handleFunctionCall (functionName, item) {
        // action.fonction.nom
        // action.fonction.messageNo
        this[functionName](item)
      },
      getStatusName (statut) {
        if (statut === 8) return this.$i18n.t('order.operation.status.8')
        if (statut === -1) return this.$i18n.t('Cancel')
        return this.$i18n.t('order.status.In_progress')
      },
      getPickupDate (date) {
        if (date) return format(parseISO(date), `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[this.language] })
        return null
      },
      getEditButton (statut, etape) {
        // If statut est 1, il peut éditer
        if (statut === 1) return true
        if (statut === 2 && etape === 1) return true
        return false
      },
      makeAppointment (item) {
        // On regarde si le client de la commande a déjà une commande avec la même date de rdv. Si oui, si elle change de date, on update aussi les autres date
        const filterUserOrder = this.client.Commandes.filter(x => x.statut !== 8 && x.etape !== 1 && x.rdv_date === item.rdv_date && x.id !== item.id)

        this.dialog4 = true
        this.$nextTick(() => {
          this.$refs.ClientCommandeDemoUqamDialogMakeAppointment.getData(JSON.parse(JSON.stringify(item)), false, false, filterUserOrder)
        })
      },
      redirect (provider) {
        let url
        if (provider === 'outlook') url = outlook(this.rdvDate)
        if (provider === 'office365') url = office365(this.rdvDate)
        if (provider === 'google') url = google(this.rdvDate)
        if (provider === 'ics') url = ics(this.rdvDate)

        if (provider === 'ics') {
          window.open(url)
        } else {
          window.open(url, '_blank')
        }
      },
      editView (item) {
        // Si le statut est plus grand que 2 le client ne peut plus modifier la commande. il est en mode view
        this.state = 'view'
        if (this.getEditButton(item.statut, item.etape)) this.state = 'edit'

        // COMMANDER UNE LIGNE CELLULAIRE (Carte de crédit Type 2)
        if ((item.type === 1 || item.type === 2) && item.mode_paiement === 2) {
          this.dialog3 = true
          this.$nextTick(() => {
            // On envoie l'objet de la commande dans le composant
            this.$refs.orderNewLineCredit.editOrder(item)
          })
        }
        // COMMANDER UNE LIGNE CELLULAIRE (UBR Type 1)
        if ((item.type === 1 || item.type === 2) && item.mode_paiement === 1) {
          this.dialog1 = true
          this.$nextTick(() => {
            // On envoie l'objet de la commande dans le composant
            this.$refs.orderNewLineUbr.editOrder(item)
          })
        }
        // COMMANDER UN TELEPHONE CELLULAIRE (UBR Type 1)
        if (item.type === 3 && item.mode_paiement === 1) {
          this.dialog2 = true
          this.$nextTick(() => {
            // On envoie l'objet de la commande dans le composant
            this.$refs.orderNewPhoneUbr.editOrder(item)
          })
        }
      },
      remove (id) {
        Swal.fire({
          title: `${this.$i18n.t('Are_you_sure')}?`,
          html: `${this.$i18n.t('order.delete_order')} <b>${id}</b>.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Yes_delete_it_f'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await CommandesService.remove(id)
              // On update le client
              EventBus.$emit('update-order')
              Swal.fire(
                `${this.$i18n.t('Deleted')}!`,
                `${this.$i18n.t('order.Order_has_been_deleted')}.`,
                'success',
              )
            } catch (error) {
              console.log(error)
            }
          }
        })
      },
      async orderComfirm (item) {
        // Tous ce qui est commandé par UBR pas de contrat à approuvé
        if (item.mode_paiement === 1) {
          let message = `${this.$i18n.t('order.swal.text.confirm_order')} <b>${item.id}</b> ?`
          if (item.livraison) {
            message = `${this.$i18n.t('order.swal.text.confirm_order')} <b>${item.id}</b> ? <br> ${this.$i18n.t('order.swal.text.confirm_order_delivery')}
            <br>
            <br>
            <b>${item.livraison_adresse.prenom.toUpperCase()} ${item.livraison_adresse.nom.toUpperCase()}</b>
            <br>
            ${item.livraison_adresse.adresse}
            <br>
            ${item.livraison_adresse.ville}, ${item.livraison_adresse.province}
            <br>
            ${item.livraison_adresse.code_postal}
            <br>
            <br>
            ${this.$i18n.t('order.swal.text.confirm_order_delivery1')}
            `
          }

          Swal.fire({
            title: `${this.$i18n.t('Are_you_sure')}?`,
            html: message,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('Confirm'),
            cancelButtonText: this.$i18n.t('Cancel'),
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                // Start Spinner
                this.$loading.show()
                let mustApprove = false
                // On va chercher le (les) responsable
                const responsableEmail = await CommandesService.responsableCodeFacturation(item.code_facturation)

                // Si c'est la personne qui est responsable, elle n'a pas besoin d'approbation
                mustApprove = this.getApprobation(responsableEmail)

                if (mustApprove) {
                  // On confirme la commande
                  const data = { date_confirmee: Date.now(), statut: 2, etape: 3, responsableUBR: responsableEmail }
                  await CommandesService.confirmOrder(item.id, data)

                  // Message pour le client
                  const message = responsableEmail.length ? this.messageConfirmation[this.language].replace('[|COMMANDES_RESPONSABLE_CODE_FACTURATION|]', responsableEmail) : null

                  // Ouvre un dialog pour informer le client
                  this.$dialog.show({ message: message, print: false, html: true, iconState: true, iconColor: 'success', iconName: 'mdi-check-circle' })
                } else {
                  // Statut et Étape
                  this.getNextStatutStep(item)
                  const { statut, etape } = this.getNextStatutStep(item)
                  const data = { date_confirmee: Date.now(), statut, etape }
                  await CommandesService.update(data, item.id)
                }

                // On update le client
                EventBus.$emit('update-order')
              } catch (error) {
                // Stop Spinner
                this.$loading.hide()
                console.log(error)
              }
            }
          })
        } else {
          // Tous ce qui n'est pas UBR... Pour approuvé le contrat
          // On mets le checkbox null pour la validation
          this.contratCheckBoxConfirmation = null

          // On mets la commande dans cette variable. Voir la fonction 'orderComfirmation'
          this.orderItem = item

          // Start Spinner
          this.$loading.show()
          try {
            const message = await MessagesService.replace(8, this.language)
            this.contratConfirmation = message.body.replace('[|COMMANDES_CLIENT_PRENOM|]', this.client.prenom.charAt(0).toUpperCase() + this.client.prenom.slice(1))
              .replace('[|COMMANDES_CLIENT_NOM|]', this.client.nom.charAt(0).toUpperCase() + this.client.nom.slice(1))

            // Stop Spinner
            this.$loading.hide()
            // Open dialog
            this.dialogContrat = true
          } catch (error) {
            // Stop Spinner
            this.$loading.hide()
            console.log(error)
          }
        }
      },
      async orderComfirmAutorisation (item) {
        const message = this.$i18n.t('order.swal.text.Confirm_autorisation_telus').replace('[|CLIENT_NOM_LONG|]', this.parameterInstitutionName)
        Swal.fire({
          title: 'Attention',
          html: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('order.swal.button.Confirm_autorisation_telus'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // // On met à jour la commande
              const data = { statut: 3, etape: 1 }
              await CommandesService.update(data, item.id)

              // // On update le client
              EventBus.$emit('update-order')
            } catch (error) {
              console.error(error)
            }
          }
        })
      },
      getApprobation (responsableEmail) {
        // Si il n'y a pas de courriel, on ne fait pas d'approbation
        if (!responsableEmail.length) return false
        // Si on se trouve soi-même comme approbateur pas besoin d'approbation
        const findUser = responsableEmail.find(item => item === this.userInfo.info.mail)
        // return true or false
        return !findUser
      },
      async orderComfirmAppointment (item) {
        let statut
        let etape
        if (item.type === 1) {
          statut = 3
          etape = 5
        } else if (item.type === 2) {
          // 1 = Telus, 2 = pc mobile, public mobile, koodo, 3- Autre fournisseur
          if (item.migration_type === 3) {
            statut = 3
            etape = 7
          } else {
            statut = 3
            etape = 6
          }
        } else if (item.type === 3) {
          statut = 4
          etape = 1
        }

        Swal.fire({
          title: `${this.$i18n.t('order.swal.title.Appointment_confirm')}`,
          html: this.messageRDV[this.language],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Confirm'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Start Spinner
              this.$loading.show()
              try {
                // Message
                // Récupération des message de rendez-vous
                const message = await MessagesService.replace(29, this.client.langue)
                const messageHtml = message.body.replace('[|RDV_DATE|]', item.rdv_date ? format(parseISO(item.rdv_date), `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[this.client.langue] }) : '')
                  .replace('[|COMPTOIR|]', item.Comptoir ? item.Comptoir[this.client.langue] : '')

                const updateCommande = {
                  statut,
                  etape,
                  rdv_confirme: true,
                  sendMail: true,
                  mail: {
                    mailBody: messageHtml,
                    mailSujet: message.subject,
                    mailTo: this.client.email,
                    commandePdf: true,
                  },
                }

                await CommandesService.confirmationRequest(item.id, updateCommande)
                // On update le client
                EventBus.$emit('update-order')
                // Stop Spinner
                this.$loading.hide()

                // On date au client si il veut ajouter cet évènement au calendrier
                this.rdvDate = {
                  title: this.$i18n.t('order.Calendar_add_title'),
                  description: `${this.$i18n.t(`order.type.${item.type}`)}`,
                  start: item.rdv_date,
                  duration: [20, 'minute'],
                  location: item.Comptoir[this.language].replace(/<[^>]*>/g, ''),
                }
                this.dialog5 = true
              } catch (error) {
                console.log(error)
                // Stop Spinner
                this.$loading.hide()
              }
            } catch (error) {
              console.log(error)
            }
          }
        })
      },
      async orderComfirmation () {
        // Statut et Étape
        const { statut, etape } = this.getNextStatutStep(this.orderItem)
        // Close dialog
        this.dialogContrat = false

        // Start Spinner
        this.$loading.show()
        try {
          // On confirme la commande
          const data = { date_confirmee: Date.now(), statut, etape }
          await CommandesService.update(data, this.orderItem.id)

          // On update le client
          EventBus.$emit('update-order')
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      async enterNoSimCard (item) {
        const inputValue = ''
        const { value: noSimCard } = await Swal.fire({
          title: this.$i18n.t('order.operation.Enter_no_sim_card'),
          input: 'text',
          inputLabel: this.$i18n.t('order.swal.text.The_SIM_card_number_must_19_digits'),
          inputValue: inputValue,
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) return this.$i18n.t('You_need_to_write_something')
            if (value.length !== 19) return this.$i18n.t('order.swal.text.The_SIM_card_number_must_19_digits')
            if (isNaN(value)) return this.$i18n.t('order.swal.text.Number_only')
          },
        })

        if (noSimCard) {
          const data = { no_carte_sim: noSimCard, statut: 4, etape: 3 }
          await CommandesService.update(data, item.id)

          // On update le client
          EventBus.$emit('update-order')
        }
      },
      closeDialog () {
        this.dialog1 = false
        this.dialog2 = false
        this.dialog3 = false
        this.dialog4 = false
        this.dialog5 = false
      },
      // Fonction appellé des composants des dialogs lorsqu'un utilisateur soumets une commande
      async submit (submitForm) {
        // Close Dialog
        this.closeDialog()
        // Start Spinner
        this.$loading.show()
        try {
          // Sauvegarde da la commande
          await CommandesService.update(submitForm, submitForm.id)

          // On update le client
          EventBus.$emit('update-order')
          // Message
          const message = this.message[this.language].replace('[|COMMANDE_ID|]', submitForm.id)
          // Ouvre un dialog pour informer le client
          this.$dialog.show({ message: message, print: false, html: true, iconState: true, iconColor: 'success', iconName: 'mdi-check-circle' })
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      async submitAddToCalendar (submitForm) {
        // Close Dialog
        this.closeDialog()
        // Start Spinner
        this.$loading.show()
        try {
          // Sauvegarde da la commande
          await CommandesService.update(submitForm, submitForm.id)

          // On update le client
          EventBus.$emit('update-order')

          // Stop Spinner
          this.$loading.hide()

          // On demande au client si il veut ajouter cet évènement au calendrier
          this.rdvDate = {
            title: this.$i18n.t('order.Calendar_add_title'),
            description: `${this.$i18n.t(`order.type.${submitForm.type}`)}`,
            start: submitForm.rdv_date,
            duration: [20, 'minute'],
            location: submitForm.Comptoir[this.language].replace(/<[^>]*>/g, ''),
          }

          this.dialog5 = true
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
    },
  }
</script>
